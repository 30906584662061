































































import Vue from 'vue'
import Component from 'vue-class-component'
import { ProductListItem } from '@/shared/models/cakes'
import { Prop } from 'vue-property-decorator'
import { PREPAY, MINCHECK_VALUE } from '@/shared/const'
import { PromoCode } from '@/shared/models/orders'

@Component
export default class CartTotal extends Vue {
  @Prop({ default: 'Итого' }) totalPriceLabel!: string
  @Prop({ default: PREPAY.FULL.value }) prepayValue!: number
  @Prop({ default: false }) showDeliveryInfo!: boolean
  @Prop({
    default: () => {
      return {
        label: '',
        value: 0
      }
    }
  }) deliveryPrice!: { label: string, value: number }

  data(): Record<string, unknown> {
    return {
      PREPAY,
      MINCHECK_VALUE,
      isPolicyAccepted: false
    }
  }

  get cakes(): Array<ProductListItem> {
    return this.$store.getters.cart
  }

  get promoCode(): PromoCode | null {
    return this.$store.getters.promoCode
  }

  get promoDiscountValue(): number {
    return this.promoCode
      ? Number(this.promoCode.amount)
      : 0
  }

  get totalPrice(): number {
    let price = this.cakes.reduce((acc, cake) => {
      return acc + (Number(cake.acf.cake_max_layers ? cake?.weight : 1) * Number(cake?.price) * cake.quantity)
    }, 0)

    if (this.promoCode && this.promoCode.discountType === 'percent') {
      price = price - (price * (this.promoDiscountValue / 100))
    } else {
      price = price - this.promoDiscountValue
    }
    return price * this.prepayValue + (this.deliveryPrice?.value || 0)
  }

  get totalPriceValue(): string | number {
    return Number(Number.isInteger(this.totalPrice) ? this.totalPrice : +this.totalPrice.toFixed(2)).toLocaleString()
  }

  get isPrepay(): boolean {
    return this.prepayValue !== PREPAY.FULL.value
  }

  get totalCakeQuantity(): number {
    return this.cakes.reduce((acc, cur) => {
      acc += cur.quantity
      return acc
    }, 0) % 100
  }

  get totalItemsTitle(): string {
    let result = ''
    let counter = this.totalCakeQuantity
    if (counter >= 5 && counter <= 20) {
      result = 'товаров'
    } else {
      counter = this.totalCakeQuantity % 10
      if (counter === 1) {
        result = 'товар'
      } else if (counter >= 2 && counter <= 4) {
        result = 'товара'
      } else {
        result = 'товаров'
      }
    }
    return result
  }
}
